<template>
  <div class="evaluation"  v-loading="submitLoading" :style="submitLoading ? 'overflow-y:hidden;' : 'overflow-y: scroll;'" element-loading-text="数据提交中" v-if="questionList && questionList[sort - 1]">
    <div class="progress">
      <el-row :gutter="10">
        <el-col :span="20">
          <el-progress v-if="percentage" :percentage="percentage" :show-text="false" style="position: relative; top: 0.5rem" color="#73986D" ></el-progress>
        </el-col>
        <el-col :span="4">
          <p class="sortBox">
            <span class="sort">{{ sort }}</span><span class="quesLen">/{{ questionList.length }}</span>
          </p>
        </el-col>
      </el-row>
    </div>
    <div class="main" v-if="questionList && questionList[sort - 1]">
      <div class="moduleName" v-if="questionList[sort - 1].moduleName">
        {{ questionList[sort - 1].moduleName }}
      </div>
      <div class="moduleDesc" v-if="questionList[sort - 1].moduleDesc">
        {{ questionList[sort - 1].moduleDesc }}
      </div>
      <div class="moduleQues" v-for="(item, index) in questionList[sort - 1].questionArray" :key="index">
        <b class="quesInfo"><span v-if="questionList[sort - 1].questionArray.length > 1" >{{ index + 1 }}.</span >&nbsp;{{ item.quesInfo }} <span v-if="item.least || item.most || item.shortest || item.longest">（<span v-if="item.least">至少{{item.least}}项</span> <span v-if="item.most">至多{{item.most}}项</span><span v-if="item.shortest">至少{{item.shortest}}字</span> <span v-if="item.longest">至多{{item.longest}}字</span>）</span></b>
        <img class="quesImg" style="width:100%;margin-bottom:1rem;"  v-if="item.quesImg" :src="item.quesImg" alt="">
        <!-- 单项选择题 -->
        <div class="radio" v-if="item.answerType == 'A'">
          <ul>
            <li v-for="(each, idx) in item.optionArray" :key="idx">
              <div @click="groupRadioCheck(item, each)" :class="each.optionId == item.answer ? 'item active' : 'item'">
                <p>{{ each.optionInfo }}</p>
              </div>
            </li>
          </ul>
        </div>
        <!-- 多选题 -->
        <div class="checkbox" v-if="item.answerType == 'C'">
          <ul>
            <li v-for="(each, idx) in item.optionArray" :class="each.checked ? 'active' : ''" :key="idx">
              <p>
                <el-checkbox v-model="each.checked" @change="checkboxCheck(idx,item)">{{ each.optionInfo }}</el-checkbox>
              </p>
            </li>
          </ul>
        </div>
        <!-- 填空题 -->
        <div class="inputBox" v-if="item.answerType == 'B3'">
          <el-input v-model="item.answer" type="text" placeholder="请输入" @input="textInput"></el-input>
          <span class="unit">{{item.suffix}}</span>
        </div>
        <!-- 滑块题 -->
        <div class="slider" v-if="item.answerType == 'S'">
          <span class="leftDesc" v-if="item.leftDesc">{{item.leftDesc}}</span>
          <el-slider v-model="item.answer" :min="item.lowest" :max="item.bigest" :step="item.step" :marks="item.marks" @change="sliderChange(item)"></el-slider>
          <span class="rightDesc" v-if="item.rightDesc">{{item.rightDesc}}</span>
        </div>
        <!-- 下拉题 -->
        <div class="selectBox" v-if="item.answerType == 'A4'">
          <el-select v-model="item.answer" @change="selectChange($event,item)">
            <el-option v-for="each in item.optionArray" :key="each.optionId" :label="each.optionInfo" :value="each.optionId"></el-option>
          </el-select>
        </div>
         <!-- 文本题 -->
        <div class="textBox" v-if="item.answerType == 'B'">
          <el-input v-model="item.answer" type="textarea" placeholder="请输入" :minlength="item.shortest ? item.shortest : ''" :maxlength="item.longest ? item.longest : ''" @input="textInput" :autosize="{minRows:4}" show-word-limit></el-input>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="btns">
        <span class="prev" @click="prev" v-if="sort > 1">上一题</span>
        <span class="next" @click="next" v-if="sort != questionList.length"
          >下一题</span
        >
        <span v-if="sort == questionList.length" class="submit" @click="checkQues(false)"
          >提交</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from 'mint-ui';
import { getQuesDispense, saveObjectiveAns } from '@/api/index';
export default {
  data() {
    return {
      submitLoading:false,
      questionList: [],
      sort: 1,
      answerList: [],
      startTime: 0, //起始时间
      endTime: 0, //结束时间
    };
  },
  computed: {
    percentage() {
      return parseInt((this.sort / this.questionList.length) * 100);
    },
  },
  methods: {
    //获取问题
    getQuestion() {
      let query = {
        fmUserId: this.$util.getStorage('fmUserId'),
        quesNum:'0-1'
      }
      getQuesDispense(query).then((res) => {
          if (res.code === 0) {
            this.questionList = res.data.list;
            this.questionList.forEach(item => {
              item.questionArray.forEach(ques => {
                ques.answer = '';
                if(ques.answerType === 'S') {
                  ques.marks = {};
                  ques.answer = ques.optionArray[0].optionScore;
                  ques.optionArray.forEach(opt => {
                    this.$set(ques.marks, [opt.optionScore], opt.optionInfo);
                  });
                }
                if(ques.answerType === 'C') {
                  ques.optionArray.forEach(opt => {
                    opt.checked = false;
                  })
                }
              })
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //单选
    groupRadioCheck(item, each) {
      item.answer = each.optionId;
      item.optionId = each.optionId;
      item.optionScore = each.optionScore;
      this.$forceUpdate();
    },
    //多选
    checkboxCheck(idx,item) {
      this.$forceUpdate();
      let checkLen = item.optionArray.filter((item) => item.checked).length;
      if(checkLen > item.most) {
        Toast('至多'+ item.most +'项');
        item.optionArray[idx].checked = false;
        return false
      }
      item.answerArr = [];
      item.optionIdArr = [];
      item.optionScoreArr = [];
      item.optionArray.forEach((option) => {
        if (option.checked) {
          item.answerArr.push(option.optionId);
          item.optionIdArr.push(option.optionId);
          item.optionScoreArr.push(option.optionScore);
        }
      });
      item.answer = item.answerArr.join(',');
      item.optionId = item.optionIdArr.join(',');
      item.optionScore = item.optionScoreArr.join(',');
    },
    //滑块题
    sliderChange(item) {
      this.$forceUpdate();
      item.optionArray.forEach((option) => {
        if(option.optionScore == item.answer) {
          item.optionId = option.optionId;
          item.optionScore = option.optionScore;
        }
      })
    },
    //下拉题
    selectChange(event,item) {
      item.optionArray.forEach((option) => {
        if(option.optionId == event) {
          item.optionId = option.optionId;
          item.optionScore = option.optionScore;
        }
      })
      this.$forceUpdate();
    },
    //文本题
    textInput() {
      this.$forceUpdate();
    },
    //上一题
    prev() {
      if (this.sort <= 1) {
        return false;
      }
      this.sort--;
      this.$nextTick(()=> {
        document.querySelector('.evaluation').scrollTop = 0;
      })
    },
    //下一题
    next() {
      if (this.sort >= this.questionList.length) {
        return false;
      }
      this.checkQues(true);
    },
    //校验当前答题
    checkQues(flag) {
      //多选题目至少数量限制
      let leastLen = this.questionList[this.sort - 1].questionArray.filter(
        (item) => item.answerType =='C' && (item.answerArr.length < item.least)
      )
      if(leastLen.length > 0) {
        Toast('多选题已选数量不符合最低要求！')
        return false
      }
      //输入框至少长度限制
      let shortLen = this.questionList[this.sort - 1].questionArray.filter(
        (item) => item.answerType =='B' && (item.answer.length < item.shortest)
      )
      if(shortLen.length > 0) {
        Toast('文本题输入长度不符合最低要求！')
        return false
      }
      //所有题都必须有答案
      let noAnswer = this.questionList[this.sort - 1].questionArray.filter(
        (item) => item.answer == null || item.answer == ''
      );
      if (noAnswer.length > 0) {
        Toast('请完成当前问卷')
        return false
      }
      if(flag) {
        this.sort++;
        this.$nextTick(()=> {
          document.querySelector('.evaluation').scrollTop = 0;
        })
      } else {
        this.submit();
      }
    },
    // 提交
    submit() {
      this.submitLoading = true;
      this.endTime = this.$util.getTimestamp();
      this.answerList = [];
      this.questionList.forEach((item) => {
        item.questionArray.forEach((each) => {
          if (each.answer) {
            this.answerList.push({
              fmQuesObjectiveId: each.fmQuesObjectiveId,
              moduleId: each.moduleId,
              answerType: each.answerType,
              answer: each.answer,
              optionId: each.optionId !== '' ? each.optionId : '',
              optionScore: each.optionScore !== ''  ? each.optionScore : '',
            });
          }
        });
      });

      let params = {
        fmUserId: this.$util.getStorage('fmUserId'), //用户ID
        totalTime: this.endTime - this.startTime,
        myAns: this.answerList,
        quesNum:'0-1'
      };
      saveObjectiveAns(params).then((res) => {
        if (res.data) {
          sessionStorage.setItem(
            'fmProductQuestionId',
            res.data.fmProductQuestionId
          );
          //小题编号
          sessionStorage.setItem('quesNum', res.data.quesNum);
          //是否完成
          sessionStorage.setItem('isFinished', res.data.isFinished);
          if(res.data.isFinished == 1) {
            this.$router.replace({
              path: '/mQuesFinish',
              query: {
                isFinished: res.data.isFinished,
              },
            });
          } else {
            if(res.data.quesNum == '0-2') {
              this.$router.replace('/mEvaluation');
            } else {
              this.$router.replace({
                path: '/mQuesFinish',
                query: {
                  isFinished: res.data.isFinished,
                },
              });
            }
          }
          this.submitLoading = false;
        }
      })
      .catch((error) => {
        this.submitLoading = false;
        console.log(error);
      });
    },
  },
  created() {
    let evalName = JSON.parse(sessionStorage.getItem('login')).evalName;
    document.title = evalName;
    this.getQuestion();
  },
  mounted() {
    this.startTime = this.$util.getTimestamp();
  },
};
</script>

<style lang="scss" scoped>
::v-deep input[type='number'] {
  border-radius: 1.3rem;
  text-align: center;
}
.evaluation {
  font-family: 'SYHeiNormal';
  width: 100vw;
  height: 100%;
  background: #ffffff;
  font-size: 0.95rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling:touch;
  .progress {
    width: 100%;
    height: 3.5rem;
    padding: 1rem;
    background: #ffffff;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    .sortBox {
      text-align: center;
      .sort {
        color: #73986d;
        font-size: 1.3rem;
      }
      .quesLen {
        font-size: 0.9rem;
      }
    }
  }
  .main {
    width: 90%;
    margin: 0 auto;
    padding-top: 4rem;
    padding-bottom: 1rem;
    border-radius: 0.3rem;
    .moduleName {
      font-size: 1.1rem;
      font-weight: 550;
      line-height: 1.2rem;
      margin-bottom: 0.8rem;
    }
    .moduleDesc {
      color: #666666;
      line-height: 1.4rem;
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0.8rem;
    }
    .moduleQues {
      margin-bottom: 2rem;
      .quesInfo {
        width: 100%;
        color: #000000;
        display: inline-block;
        line-height: 1.2rem;
        font-size: 0.95rem;
        font-weight: 400;
        margin-bottom: 1.25rem;
      }
      .radio {
        font-size: 0.95rem;
        li {
          width: 100%;
          vertical-align: top;
          margin: 0 auto 0.6rem auto;
          .item {
            // min-height: 2.6rem;
            padding: 0.4rem;
            line-height: 1.3rem;
            margin-bottom: 0.5rem;
            color: #73986d;
            background: #edf3ed;
            cursor: pointer;
            text-align: center;
            border-radius: 1.3rem;
          }
          .active {
            color: #ffffff;
            background: #73986d;
          }
        }
      }
      .checkbox {
        font-size: 0.95rem;
        li {
          vertical-align: top;
          width: 100%;
          // min-height: 2.6rem;
          padding: 0.4rem;
          line-height: 1.3rem;
          border-radius: 1.3rem;
          margin: 0 auto 0.6rem auto;
          color: #73986d;
          background: #edf3ed;
          cursor: pointer;
          text-align: center;
          p {
            .optionInfo {
              width: 15rem;
              display: inline-block;
            }
          }
        }
        ::v-deep .el-checkbox__label {
          width: 16rem;
          color: #73986d;
          display: inline-grid;
          white-space: pre-line;
          word-wrap: break-word;
          overflow: hidden;
          line-height: 1.3rem;
        }
        .active {
          color: #ffffff;
          background: #73986d;
          ::v-deep .el-checkbox__label {
            color: #ffffff;
          }
        }
      }
      .inputBox {
        display: flex;
        .unit {
          display: inline-block;
          margin-left: 0.5rem;
          margin-right: 1rem;
          font-size: 1.1rem;
          line-height: 2.4rem;
        }
      }
      .slider {
        padding: 1.5rem;
        color: #909399;
        // line-height: 2rem;
        position: relative;
        ::v-deep .el-slider__marks-text {
          white-space: nowrap;
          font-size: 0.7rem;
        }
        .leftDesc {
          font-size: 0.85rem;
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
        }
        .rightDesc {
          font-size: 0.85rem;
          display: inline-block;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      .selectBox {
        // ::v-deep .el-select {
        //   width: 6.5rem;
        // }
        ::v-deep .el-select .el-input__inner {
          border-radius: 1.3rem;
        }
      }
    }
  }
  .bottom {
    width: 90%;
    margin: 0 auto 3rem auto;
    overflow: hidden;
    text-align: center;
    .btns {
      color: #289257;
      font-size: 0.95rem;
      display: inline-block;
      .prev,
      .next,
      .submit {
        width: 9rem;
        height: 2.6rem;
        line-height: 2.6rem;
        border-radius: 1.3rem;
        display: inline-block;
      }
      .prev {
        color: #73986d;
        background: #eef3ee;
        margin-right: 1.8rem;
      }
      .next,
      .submit {
        color: #ffffff;
        background: #73986d;
      }
    }
  }
}
</style>
